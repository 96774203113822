import '../App.css';
import '../css/Body.css';

function Experience(props) {
  return (
    <div>
        <section className="card">
            <h3>XP to Level Up</h3>
            <table>
                <thead>
                    <tr>
                        <td>Level</td>
                        <td>XP</td>
                        <td>Prof. Bonus</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>0</td>
                        <td>+2</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>300</td>
                        <td>+2</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>900</td>
                        <td>+2</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>2,700</td>
                        <td>+2</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>6,500</td>
                        <td>+3</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>14,000</td>
                        <td>+3</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>23,000</td>
                        <td>+3</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>34,000</td>
                        <td>+3</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>48,000</td>
                        <td>+4</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>64,000</td>
                        <td>+4</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>85,000</td>
                        <td>+4</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>100,000</td>
                        <td>+4</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>120,000</td>
                        <td>+5</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>140,000</td>
                        <td>+5</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>165,000</td>
                        <td>+5</td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>195,000</td>
                        <td>+5</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>225,000</td>
                        <td>+6</td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>265,000</td>
                        <td>+6</td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>305,000</td>
                        <td>+6</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>355,000</td>
                        <td>+6</td>
                    </tr>
                </tbody>
            </table>
        </section>
        <section className="card">
            <h3>XP Thresholds per Level</h3>
            <table>
                <thead>
                    <tr>
                        <td>Lvl.</td>
                        <td>Easy</td>
                        <td>Med.</td>
                        <td>Hard</td>
                        <td>Deadly</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>25</td>
                        <td>50</td>
                        <td>75</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>50</td>
                        <td>100</td>
                        <td>150</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>75</td>
                        <td>150</td>
                        <td>225</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>125</td>
                        <td>250</td>
                        <td>375</td>
                        <td>500</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>250</td>
                        <td>500</td>
                        <td>750</td>
                        <td>1,100</td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>300</td>
                        <td>600</td>
                        <td>900</td>
                        <td>1,400</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>350</td>
                        <td>750</td>
                        <td>1,100</td>
                        <td>1,700</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>450</td>
                        <td>900</td>
                        <td>1,400</td>
                        <td>2,100</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>550</td>
                        <td>1,100</td>
                        <td>1,600</td>
                        <td>2,400</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>600</td>
                        <td>1,200</td>
                        <td>1,900</td>
                        <td>2,800</td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>800</td>
                        <td>1,600</td>
                        <td>2,400</td>
                        <td>3,600</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>1,000</td>
                        <td>2,000</td>
                        <td>3,000</td>
                        <td>4,500</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>1,100</td>
                        <td>2,200</td>
                        <td>3,400</td>
                        <td>5,100</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>1,250</td>
                        <td>2,500</td>
                        <td>3,800</td>
                        <td>5,700</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>1,400</td>
                        <td>2,800</td>
                        <td>4,300</td>
                        <td>6,400</td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>1,600</td>
                        <td>3,200</td>
                        <td>4,800</td>
                        <td>7,200</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>2,000</td>
                        <td>3,900</td>
                        <td>5,900</td>
                        <td>8,800</td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>2,100</td>
                        <td>4,200</td>
                        <td>6,300</td>
                        <td>9,500</td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>2,400</td>
                        <td>4,900</td>
                        <td>7,300</td>
                        <td>10,900</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>2,800</td>
                        <td>5,700</td>
                        <td>8,500</td>
                        <td>12,700</td>
                    </tr>                    
                </tbody>
            </table>
        </section>
        <section className="card">
            <h3>XP Adjustment</h3>
            <table>
                <thead>
                    <tr>
                        <td># Monsters</td>
                        <td>XP Multiplier</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>x 1.0</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>x 1.5</td>
                    </tr>
                    <tr>
                        <td>3-6</td>
                        <td>x 2.0</td>
                    </tr>
                    <tr>
                        <td>7-10</td>
                        <td>x 2.5</td>
                    </tr>
                    <tr>
                        <td>11-14</td>
                        <td>x 3.0</td>
                    </tr>
                    <tr>
                        <td>15+</td>
                        <td>x 4.0</td>
                    </tr>
                </tbody>
            </table>
            <p>Multiplier calculations taken from <a href="https://blackcitadelrpg.com/challenge-rating-5e/">Black Citadel RPG</a>.</p>
        </section>
        <section className="card">
            <h3>Encounter Calculator</h3>
            <p>Encounter calculations can be made on <a href="https://koboldplus.club/#/encounter-builder">Kobold+ Fight Club</a>.</p>
        </section>
    </div>
  );
}

export default Experience;