import {useState} from 'react';
import '../css/Header.css';
import Hamburger from '../img/hamburger.png';
import Nav from './Nav';

function Header(props){

    const [showMenu, setShowMenu] = useState(false);

    function toggleMenu(){
        setShowMenu(current => !current);
    }

    return(
        <div>
            <header className="Header">
                <div className="left">
                    <img id="menu-button" className="menu-button" src={Hamburger} onClick={toggleMenu} />
                </div>
                <div className="center">
                    <h1>D&D DM Toolkit</h1>
                </div>
                <div className="right">

                </div>
            </header>
            {showMenu && <Nav />}
        </div>
    );
}

export default Header;