import '../css/Body.css';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/home.jsx';
import Experience from '../pages/experience.jsx';
import Inns from '../pages/inns.jsx';
import MagicItems from '../pages/magicitems.jsx';
import Prices from '../pages/prices.jsx';
import Treasure from '../pages/treasure.jsx';

function Body(props){
    return(
        <Routes>
            <Route exact path='/' element={<Home />}></Route>
            <Route exact path='/experience' element={<Experience />}></Route>
            <Route exact path='/inns' element={<Inns />}></Route>
            <Route exact path='/magicitems' element={<MagicItems />}></Route>
            <Route exact path='/prices' element={<Prices />}></Route>
            <Route exact path='/treasure' element={<Treasure />}></Route>
        </Routes>
    );
}

export default Body;

