import '../css/Body.css';

function Home(props){
    return(
        <div>
            <section className="card">
                <table>
                    <thead>
                        <tr>
                            <td>Task Difficulty</td>
                            <td>DC</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Trivial</td>
                            <td>5</td>
                        </tr>
                        <tr>
                            <td>Easy</td>
                            <td>8</td>
                        </tr>
                        <tr>
                            <td>Medium</td>
                            <td>10</td>
                        </tr>
                        <tr>
                            <td>Tricky</td>
                            <td>12</td>
                        </tr>
                        <tr>
                            <td>Hard</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td>Very hard</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>Incredibly hard</td>
                            <td>25</td>
                        </tr>
                        <tr>
                            <td>Why bother?</td>
                            <td>30</td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </div>
    );
}

export default Home;