import { useEffect, useState } from "react";
import axios from 'axios';
import '../css/Search.css';

function Search({ dataPath, dataType, columns }){
    const [value, setValue] = useState('');
    const [hidden, setHidden] = useState(true);
    const [allData, setAllData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    function checkSearch(data){
        let match = false;
        for (let datum in data ){
            if(data[datum].toLowerCase().indexOf(value.toLowerCase()) > -1){
                match = true;
            }
        }
        return match;
    }

    useEffect(() => {
        axios.get(dataPath).then((res) => {
          setAllData(res.data[dataType]);
          setFilteredData(res.data[dataType]);
        });
      }, []);    

    useEffect(() => {
        const fetchData = async () => {
          try {
            setFilteredData(allData.filter(checkSearch));
            if(allData.filter(checkSearch).length === 0 && value.length > 0){
                setHidden(false);
            } else {
                setHidden(true);
            }
          } catch (error) {
            console.log(error);
          }
        };
    
        fetchData();
      }, [value]);

    return(
        <div>
            <form className="search-bar">
                <input 
                    id="search-bar" 
                    className="search-bar" 
                    type="text" 
                    placeholder="Search..." 
                    value={value} 
                    autoComplete="off"
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                />
            </form>
            {!hidden && <div id="nothing-alert">Nothing Found</div>}
            {hidden && <table id="search-table">
                <thead>
                    <tr>
                        {
                            columns.map(col =>    
                                <td>{col}</td>
                            )
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        filteredData.map(item => 
                            <tr key={item.name}>
                                {
                                    columns.map(col =>   
                                        <td>{item[col.toLowerCase()]}</td>
                                    )
                                }
                            </tr>
                        )
                    }
                </tbody>
            </table>}
        </div>
    );
}

export default Search;