import { useEffect, useState } from 'react';
import '../css/Body.css';
import Submit from '../img/submit.png';
import axios from 'axios';
import Search from '../components/Search';

function Inns(props){

    // GENERATE INN NAMES

    const [innQuality, setInnQuality] = useState('any');
    const [names, setNames] = useState('');
    const [hidden, setHidden] = useState('hidden');
    const [innsData, setInnsData] = useState([]);

    const adjectives = {
        'any': ['Obnoxious', 'Deadly', 'Lethal', 'Dirty', 'Dingy', 'Sulky', 'Nasty', 'Infested', 'Last', 'Questionable', 'Forbidden', 'Drunken', 'Lame', 'Odious', 'Budget', 'Friendly', 'Imperfect', 'Ye Olde', 'Rickety', 'Drab', 'Hungry', 'Limping', 'Skinny', 'Wicked', 'Infernal', 'Candid', 'Apathetic', 'Clean', 'Tidy', 'Timid', 'Serious', 'Decent', 'Main', 'Cozy', 'Snuggly', 'Better', 'Happy', 'Fierce', 'Wild', 'Baffled', 'Cheerful', 'Diplomatic', 'Eager', 'Trusting', 'Modest', 'Practical', 'Steadfast', 'Sensible', 'Shy', 'Congenial', 'Snooty', 'Lovely', 'Discerning', 'Glamorous', 'Organic', 'Jovial', 'Convivial', 'Fancy', 'Elegant', 'Rich', 'Adorned', 'Deluxe', 'Lavish', 'Gaudy', 'Cushy', 'Resplendent', 'Ostentatious', 'Regal', 'Embellished', 'Elaborate', 'Ornate', 'Tasteful', 'Succulent', 'Generous', 'Posh', 'Noble', 'Sordid', 'Frumpy'],
        'squalid': ['Obnoxious', 'Deadly', 'Lethal', 'Dirty', 'Dingy', 'Sulky', 'Nasty', 'Infested', 'Last', 'Questionable', 'Forbidden', 'Drunken', 'Lame', 'Odious', 'Budget', 'Imperfect', 'Ye Olde', 'Rickety', 'Drab', 'Hungry', 'Limping', 'Skinny', 'Wicked', 'Infernal', 'Sordid', 'Frumpy'],
        'poor': ['Obnoxious', 'Deadly', 'Lethal', 'Dirty', 'Dingy', 'Sulky', 'Nasty', 'Infested', 'Last', 'Questionable', 'Forbidden', 'Drunken', 'Lame', 'Odious', 'Budget', 'Imperfect', 'Ye Olde', 'Rickety', 'Drab', 'Hungry', 'Limping', 'Skinny', 'Wicked', 'Infernal', 'Sordid', 'Frumpy'],
        'modest': ['Candid', 'Apathetic', 'Clean', 'Tidy', 'Timid', 'Serious', 'Decent', 'Main', 'Cozy', 'Snuggly', 'Better', 'Happy', 'Fierce', 'Wild', 'Baffled', 'Cheerful', 'Diplomatic', 'Eager', 'Trusting', 'Modest', 'Practical', 'Steadfast', 'Sensible', 'Shy', 'Congenial', 'Friendly'],
        'comfortable': ['Candid', 'Apathetic', 'Clean', 'Tidy', 'Timid', 'Serious', 'Decent', 'Main', 'Cozy', 'Snuggly', 'Better', 'Happy', 'Fierce', 'Wild', 'Baffled', 'Cheerful', 'Diplomatic', 'Eager', 'Trusting', 'Modest', 'Practical', 'Steadfast', 'Sensible', 'Shy', 'Congenial', 'Friendly'],
        'wealthy': ['Snooty', 'Lovely', 'Discerning', 'Glamorous', 'Organic', 'Jovial', 'Convivial', 'Fancy', 'Elegant', 'Rich', 'Adorned', 'Deluxe', 'Lavish', 'Gaudy', 'Cushy', 'Resplendent', 'Ostentatious', 'Regal', 'Embellished', 'Elaborate', 'Ornate', 'Tasteful', 'Succulent', 'Generous', 'Posh', 'Noble'],
        'aristocratic': ['Snooty', 'Lovely', 'Discerning', 'Glamorous', 'Organic', 'Jovial', 'Convivial', 'Fancy', 'Elegant', 'Rich', 'Adorned', 'Deluxe', 'Lavish', 'Gaudy', 'Cushy', 'Resplendent', 'Ostentatious', 'Regal', 'Embellished', 'Elaborate', 'Ornate', 'Tasteful', 'Succulent', 'Generous', 'Posh', 'Noble']
    };

    const nouns = {
        'any': ['Rat', 'Roach', 'Vermin', 'Water', 'Guts', 'Blood', 'Hostel', 'Flophouse', 'Scoundrel', 'Bandit', 'Snake', 'Sewer', 'Alley', 'Dog', 'Duck', 'Duckling', 'Cat', 'Bard', 'Adventurer', 'Tavern', 'Inn', 'Pub', 'Inn & Tavern', 'Goat', 'Lizard', 'Kobold', 'Goose', 'Fox', 'Mouse', 'Blanket', 'Sword', 'Mermaid', 'Boar', 'Swan', 'Hummingbird', 'Rose', 'Dragon', 'Magister', 'Patriar', 'Merchant', 'Elf', 'Lulliby', 'Noble', 'King', 'Queen', 'Serpent', 'Peacock', 'Lion', 'Pidgeon'],
        'squalid': ['Rat', 'Roach', 'Vermin', 'Water', 'Guts', 'Blood', 'Hostel', 'Flophouse', 'Scoundrel', 'Bandit', 'Snake', 'Sewer', 'Alley', 'Pidgeon'],
        'poor': ['Rat', 'Roach', 'Vermin', 'Water', 'Guts', 'Blood', 'Hostel', 'Flophouse', 'Scoundrel', 'Bandit', 'Snake', 'Sewer', 'Alley', 'Pidgeon'],
        'modest': ['Dog', 'Duck', 'Duckling', 'Cat', 'Bard', 'Adventurer', 'Tavern', 'Inn', 'Pub', 'Inn & Tavern', 'Goat', 'Lizard', 'Kobold', 'Goose', 'Fox', 'Mouse', 'Blanket', 'Sword', 'Mermaid'],
        'comfortable': ['Dog', 'Duck', 'Duckling', 'Cat', 'Bard', 'Adventurer', 'Tavern', 'Inn', 'Pub', 'Inn & Tavern', 'Goat', 'Lizard', 'Kobold', 'Goose', 'Fox', 'Mouse', 'Blanket', 'Sword', 'Mermaid'],
        'wealthy': ['Boar', 'Swan', 'Hummingbird', 'Rose', 'Dragon', 'Magister', 'Patriar', 'Merchant', 'Elf', 'Lulliby', 'Noble', 'King', 'Queen', 'Serpent', 'Peacock', 'Lion'],
        'aristocratic': ['Boar', 'Swan', 'Hummingbird', 'Rose', 'Dragon', 'Magister', 'Patriar', 'Merchant', 'Elf', 'Lulliby', 'Noble', 'King', 'Queen', 'Serpent', 'Peacock', 'Lion']
    };

    useEffect(() => {
        axios.get("/data/inns.json").then((res) => {
            setInnsData(res.data.inns);
        });
    }, []);

    const generateInns = () => {
        let namesArray = ['', '', '', ''];
        for(let i = 0; i < 4; i++){
            let adjective = adjectives[innQuality][Math.floor(Math.random() * adjectives[innQuality].length)];
            let noun = nouns[innQuality][Math.floor(Math.random() * nouns[innQuality].length)];
            namesArray[i] = "The " + adjective + " " + noun;
        }

        setNames(namesArray);
        setHidden('');
    }

    const handleSelectChange = (event) => {
        setInnQuality(event.target.value);
    }

    return(
        <div>
            <section className="card">
                <h2>Inn Generator</h2>
                <div id="generator">
                    <select name="inns" id="inns" value={innQuality} onChange={handleSelectChange} className="select-bar">
                        <option value="any">Any</option>
                        <option value="squalid">Squalid</option>
                        <option value="poor">Poor</option>
                        <option value="modest">Modest</option>
                        <option value="comfortable">Comfortable</option>
                        <option value="wealthy">Wealthy</option>
                        <option value="aristocratic">Aristocratic</option>
                    </select>
                    <button id="generate-inns-button" type="submit" onClick={generateInns}><img className="search-button" alt="Submit" title="Submit" src={Submit} /></button>
                </div>
                <div id="results">
                    <h3 id="results-title" className={hidden}>Inn Names</h3>
                    <ul id="results-list" className={hidden}>
                        <li>{names[0]}</li>
                        <li>{names[1]}</li>
                        <li>{names[2]}</li>
                        <li>{names[3]}</li>
                    </ul>
                </div>
            </section>
            <h2 className="card-header">Inns - Prices</h2>
            <section className="card">
                <h3>Squalid Inn - Menu & Services</h3>
                <table>
                    <thead>
                        <tr>
                            <td>Category</td>
                            <td>Item</td>
                            <td>Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Drinks</td>
                            <td>Water</td>
                            <td>1 cp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Stale ale</td>
                            <td>2 cp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Box wine</td>
                            <td>2 cp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Herbal tea</td>
                            <td>4 cp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Mug of ale</td>
                            <td>4 cp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>House red</td>
                            <td>4 cp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Hardtack</td>
                            <td>1 cp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Day old bread</td>
                            <td>1 cp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Bread loaf</td>
                            <td>2 cp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Bore-ridge</td>
                            <td>2 cp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Boiled eggs</td>
                            <td>2 cp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Fried mushrooms</td>
                            <td>2 cp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Vegetable stew</td>
                            <td>2 cp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Red bean pie</td>
                            <td>2 cp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Blood pudding</td>
                            <td>2 cp</td>
                        </tr>
                        <tr>
                            <td>Inn</td>
                            <td>Bedroll</td>
                            <td>7 cp</td>
                        </tr>
                        <tr>
                            <td>Inn</td>
                            <td>Bed with trunk</td>
                            <td>1 sp</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section className="card">
                <h3>Modest Inn - Menu & Services</h3>
                <table>
                    <thead>
                        <tr>
                            <td>Category</td>
                            <td>Item</td>
                            <td>Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Drinks</td>
                            <td>Water with Lemon</td>
                            <td>2 cp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Milk</td>
                            <td>2 cp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Herbal tea</td>
                            <td>4 cp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Mug of ale</td>
                            <td>4 cp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>House red</td>
                            <td>4 cp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Coffee</td>
                            <td>5 cp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Clarry wine</td>
                            <td>6 cp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Mead</td>
                            <td>6 cp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Bread loaf</td>
                            <td>2 cp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Side of veggies</td>
                            <td>5 cp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Porridge with nuts</td>
                            <td>5 cp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Cheese omelette</td>
                            <td>1 sp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Cheese</td>
                            <td>1 sp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Meat pie</td>
                            <td>1 sp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Sausage & potatoes</td>
                            <td>2 sp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Chicken & squash</td>
                            <td>2 sp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Meat chunk</td>
                            <td>3 sp</td>
                        </tr>
                        <tr>
                            <td>Inn</td>
                            <td>Shared room</td>
                            <td>5 sp</td>
                        </tr>
                        <tr>
                            <td>Inn</td>
                            <td>Private room</td>
                            <td>8 sp</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section className="card">
                <h3>Wealthy Inn - Menu & Services</h3>
                <table>
                    <thead>
                        <tr>
                            <td>Category</td>
                            <td>Item</td>
                            <td>Price</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Drinks</td>
                            <td>Latte</td>
                            <td>1 sp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Hot chocolate</td>
                            <td>1 sp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Fizzy beer</td>
                            <td>1 sp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Fire wine</td>
                            <td>2 sp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Spiced wine</td>
                            <td>2 sp</td>
                        </tr>
                        <tr>
                            <td>Drinks</td>
                            <td>Highdusk Dark</td>
                            <td>1 gp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Turkey leg</td>
                            <td>2 sp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Chicken alfredo</td>
                            <td>3 sp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Fruit & cheese platter</td>
                            <td>3 sp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Hunter's Pie</td>
                            <td>3 sp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Fancy tasting platter</td>
                            <td>1 gp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Owlbear steak & eggs</td>
                            <td>1 gp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Truffle pork</td>
                            <td>1 gp</td>
                        </tr>
                        <tr>
                            <td>Food</td>
                            <td>Veal dinner</td>
                            <td>1 gp</td>
                        </tr>
                        <tr>
                            <td>Inn</td>
                            <td>Fancy room</td>
                            <td>2 gp</td>
                        </tr>
                        <tr>
                            <td>Inn</td>
                            <td>Private suite</td>
                            <td>4 gp</td>
                        </tr>
                        <tr>
                            <td>Service</td>
                            <td>Stabling</td>
                            <td>---</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section className="card">
                <h2>Inn Search</h2>
                <Search 
                    dataPath={'/data/inns.json'}
                    dataType={'inns'}
                    columns={['Quality', 'Name', 'Location']}
                />
            </section>
        </div>
    );
}

export default Inns;