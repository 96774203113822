import { useEffect, useState } from 'react';
import '../css/Body.css';
import Submit from '../img/submit.png';
import axios from 'axios';
import Search from '../components/Search';

function Treasure(props){

    // GENERATE TREASURE BASED ON CR
    const [selectedTreasure, setSelectedTreasure] = useState('ind0');
    const [treasureHoard, setTreasureHoard] = useState('');
    const [hidden, setHidden] = useState('hidden');
    const [treasureData, setTreasureData] = useState([]);

    useEffect(() => {
        axios.get("/data/treasure.json").then((res) => {
          setTreasureData(res.data.treasure);
        });
      }, []);  

    function rollDice(num, dice){
        let result = 0;
        for (let i = 0; i < num; i++){
            result += Math.ceil(Math.random() * dice);
        }
        return result;
    }

    function getGems(num, value){
        let d4 = rollDice(1,4);
        let d6 = rollDice(1,6);
        let d8 = rollDice(1,8);
        let d10 = rollDice(1,10);
        let d12 = rollDice(1,12);
        switch (value) {
            case 10:
                switch(d12){
                    case 1: 
                        return num + " azurite gems";
                    case 2: 
                        return num + " banded agate gems";
                    case 3: 
                        return num + " blue quartz gems";
                    case 4: 
                        return num + " eye agate gems";
                    case 5: 
                        return num + " hematite gems";
                    case 6: 
                        return num + " lapis lazuli gems";
                    case 7: 
                        return num + " malachite gems";
                    case 8:
                        return num + " moss agate gems";
                    case 9: 
                        return num + " obsidian gems";
                    case 10: 
                        return num + " rhodochrosite gems";
                    case 11: 
                        return num + " tiger eye gems";
                    case 12:
                        return num + " turquoise gems";
                }
                break;
            case 50:
                switch(d12){
                    case 1: 
                        return num + " bloodstone gems";
                    case 2: 
                        return num + " carnelian gems";
                    case 3: 
                        return num + " chalcedony gems";
                    case 4: 
                        return num + " chrysoprase gems";
                    case 5: 
                        return num + " citrine gems";
                    case 6: 
                        return num + " jasper gems";
                    case 7: 
                        return num + " moonstone gems";
                    case 8:
                        return num + " onyx gems";
                    case 9: 
                        return num + " quartz gems";
                    case 10: 
                        return num + " sardonyx gems";
                    case 11: 
                        return num + " star rose quartz gems";
                    case 12:
                        return num + " zircon gems";
                }
                break;
            case 100:
                switch(d10){
                    case 1:
                        return num + " amber gems";
                    case 2: 
                        return num + " amethyst gems";
                    case 3: 
                        return num + " chrysoberyl gems";
                    case 4: 
                        return num + " coral gems";
                    case 5: 
                        return num + " garnets";
                    case 6: 
                        return num + " jade gems";
                    case 7: 
                        return num + " jet gems";
                    case 8: 
                        return num + " pearls";
                    case 9: 
                        return num + " spinel gems";
                    case 10: 
                        return num + " tourmaline gems";
                    break;
                }
            case 500:
                switch(d6){
                    case 1: 
                        return num + " alexandrite gems";
                    case 2: 
                        return num + " aquamarine gems";
                    case 3: 
                        return num + " black pearl gems";
                    case 4: 
                        return num + " blue spinel gems";
                    case 5: 
                        return num + " peridot gems";
                    case 6: 
                        return num + " topaz gems";
                }
                break;
            case 1000:
                switch(d8){
                    case 1: 
                        return num + " black opal gems";
                    case 2: 
                        return num + " blue sapphire gems";
                    case 3: 
                        return num + " emerald gems";
                    case 4: 
                        return num + " fire opal gems";
                    case 5: 
                        return num + " opal gems";
                    case 6: 
                        return num + " star ruby gems";
                    case 7: 
                        return num + " star sapphire gems";
                    case 8:
                        return num + " yellow sapphire gems";
                }
                break;
            case 1000:
                switch(d4){
                    case 1: 
                        return num + " black sapphire gems";
                    case 2: 
                        return num + " diamonds";
                    case 3: 
                        return num + " jacinth gems";
                    case 4: 
                        return num + " rubies";
                }
                break;
        }
    }

    function getArt(num, value){
        let d8 = rollDice(1,8);
        let d10 = rollDice(1,10);
        switch(value){
            case 25:
                switch(d10){
                    case 1:
                        return num + " silver ewers";
                    case 2: 
                        return num + " carved bone statuettes";
                    case 3:
                        return num + " small gold bracelets";
                    case 4:
                        return num + " cloth-of-gold vestments";
                    case 5:
                        return num + " black velvet masks stitched with silver thread";
                    case 6:
                        return num + " copper chalices with silver filigree";
                    case 7: 
                        return num + " pairs of engraved bone dice";
                    case 8: 
                        return num + " small mirror sets in a painted wooden frame";
                    case 9: 
                        return num + " embroidered silk handkerchiefs";
                    case 10:
                        return num + " gold lockets with a painted portrait inside";
                }
                break;
            case 250:
                switch(d10){
                    case 1:
                        return num + " gold ring sets with bloodstones";
                    case 2: 
                        return num + " carved ivory statuettes";
                    case 3:
                        return num + " large gold bracelets";
                    case 4:
                        return num + " silver necklaces with a gemstone pendant";
                    case 5:
                        return num + " bronze crowns";
                    case 6:
                        return num + " silk robes with gold embroidery";
                    case 7: 
                        return num + " large well-made tapestries";
                    case 8: 
                        return num + " brass mugs with jade inlay";
                    case 9: 
                        return num + " boxes of turquoise animal figurines";
                    case 10:
                        return num + " gold bird cages with electrum filigree";
                }
                break;
            case 750:
                switch(d10){
                    case 1:
                        return num + " silver chalice set with moonstones";
                    case 2: 
                        return num + " silver-plated steel long sword with jet set in hilt";
                    case 3:
                        return num + " carved harp of exotic wood with ivory inlay and zircon gems";
                    case 4:
                        return num + " small gold idol";
                    case 5:
                        return num + " gold dragon comb set with red garnets as eyes";
                    case 6:
                        return num + " bottle stopper cork embossed with gold leaf and set with amethysts";
                    case 7: 
                        return num + " ceremonial electrum dagger with a black pearl in the pommel";
                    case 8: 
                        return num + " silver and gold brooch";
                    case 9: 
                        return num + " obsidian statuette with gold fittings and inlay";
                    case 10:
                        return num + " painted gold war mask";
                }
                break;
            case 2500:
                switch(d10){
                    case 1:
                        return num + " fine gold chain sets with a fire opal";
                    case 2: 
                        return num + " old masterpiece paintings";
                    case 3:
                        return num + " embroidered silk and velvet mantle sets with numerous moonstones";
                    case 4:
                        return num + " platinum bracelet sets with a sapphire";
                    case 5:
                        return num + " embroidered glove sets with jewel chips";
                    case 6:
                        return num + " jeweled anklets";
                    case 7: 
                        return num + " gold music boxes";
                    case 8: 
                        return num + " gold circlet sets with four aquamarines";
                    case 9: 
                        return num + " eye patches with a mock eye set in blue sapphire and moonstone";
                    case 10:
                        return num + " necklace strings of small pink pearls";
                }
                break;
            case 7500:
                switch(d8){
                    case 1:
                        return num + " jeweled gold crown";
                    case 2: 
                        return num + " jeweled platinum ring";
                    case 3:
                        return num + " small gold statuette set with rubies";
                    case 4:
                        return num + " gold cup set with emeralds";
                    case 5:
                        return num + " gold jewelry box with platinum filigree";
                    case 6:
                        return num + " painted gold child's sarcophagus";
                    case 7: 
                        return num + " jade game board with solid gold playing pieces";
                    case 8: 
                        return num + " bejeweled ivory drinking horn with gold filigree";
                }
                break;
        }
    }

    function getMagicItems(num, list){
        let items = '';
        for(let i = 0; i < num; i++){
            let d100 = rollDice(1,100);
            if (i > 0){
                items += ", ";
            }
            switch(list){
                case 'A':
                    if (d100 <= 50){
                        items += "potion of healing";
                    } else if (d100 <= 60){
                        items += "spell scroll (cantrip)";
                    } else if (d100 <= 70){
                        items += "potion of climbing";
                    } else if (d100 <= 90){
                        items += "spell scroll (1st level)";
                    } else if (d100 <= 94){
                        items += "spell scroll (2nd level)";
                    } else if (d100 <= 98){
                        items +=  "potion of greater healing";
                    } else if (d100 == 99){
                        items +=  "bag of holding";
                    } else if (d100 == 100){
                        items += "driftglobe";
                    }
                break;
                case 'B':
                    if (d100 <= 15){
                        items += "potion of greater healing";
                    } else if (d100 <= 22){
                        items += "potion of fire breath";
                    } else if (d100 <= 29){
                        items += "potion of resistance";
                    } else if (d100 <= 34){
                        items += "ammunition, +1";
                    } else if (d100 <= 39){
                        items += "potion of animal friendship";
                    } else if (d100 <= 44){
                        items +=  "potion of hill giant strength";
                    } else if (d100 <= 49){
                        items +=  "potion of growth";
                    } else if (d100 <= 54){
                        items +=  "potion of water breathing";
                    } else if (d100 <= 59){
                        items +=  "spell scroll (2nd level)";
                    } else if (d100 <= 64){
                        items +=  "spell scroll (3rd level)";
                    } else if (d100 <= 67){
                        items +=  "bag of holding";
                    } else if (d100 <= 70){
                        items +=  "Keoghtom's ointment";
                    } else if (d100 <= 73){
                        items += "oil of slipperiness";
                    } else if (d100 <= 75){
                        items += "dust of disappearance";
                    } else if (d100 <= 77){
                        items += "dust of dryness";
                    } else if (d100 <= 79){
                        items += "dust of sneezing and choking";
                    } else if (d100 <= 81){
                        items += "elemental gem";
                    } else if (d100 <= 83){
                        items += "philter of love";
                    } else if (d100 == 84){
                        items += "alchemy jug";
                    } else if (d100 == 85){
                        items += "cap of water breathing";
                    } else if (d100 == 86){
                        items += "cloak of the manta ray";
                    } else if (d100 == 87){
                        items += "driftglobe";
                    } else if (d100 == 88){
                        items += "goggles of night";
                    } else if (d100 == 89){
                        items += "helm of comprehending languages";
                    } else if (d100 == 90){
                        items += "immovable rod";
                    } else if (d100 == 91){
                        items += "lantern of revealing";
                    } else if (d100 == 92){
                        items += "mariner's armor";
                    } else if (d100 == 93){
                        items += "mithral armor";
                    } else if (d100 == 94){
                        items += "potion of poison";
                    } else if (d100 == 95){
                        items += "ring of swimming";
                    } else if (d100 == 96){
                        items += "robe of useful items";
                    } else if (d100 == 97){
                        items += "rope of climbing";
                    } else if (d100 == 98){
                        items += "saddle of the cavalier";
                    } else if (d100 == 99){
                        items += "wand of magic detection";
                    } else if (d100 == 100){
                        items += "wand of secrets";
                    }
                break;
                case 'C':
                    if (d100 <= 15){
                        items += "potion of superior healing";
                    } else if (d100 <= 22){
                        items += "spell scroll (4th level)";
                    } else if (d100 <= 27){
                        items += "ammunition, +2";
                    } else if (d100 <= 32){
                        items += "potion of clairvoyance";
                    } else if (d100 <= 37){
                        items += "potion of diminution";
                    } else if (d100 <= 42){
                        items += "potion of gaseous form";
                    } else if (d100 <= 47){
                        items += "potion of frost giant strength";
                    } else if (d100 <= 52){
                        items += "potion of stone giant strength";
                    } else if (d100 <= 57){
                        items += "potion of heroism";
                    } else if (d100 <= 62){
                        items += "potion of invulnerability";
                    } else if (d100 <= 67){
                        items += "potion of mind reading";
                    } else if (d100 <= 72){
                        items += "spell scroll (5th level)";
                    } else if (d100 <= 75){
                        items += "elixir of health";
                    } else if (d100 <= 78){
                        items += "oil of etherealness";
                    } else if (d100 <= 81){
                        items += "potion of fire giant strength";
                    } else if (d100 <= 84){
                        items += "Quaal's feather token";
                    } else if (d100 <= 87){
                        items += "scroll of protection";
                    } else if (d100 <= 89){
                        items += "bag of beans";
                    } else if (d100 <= 91){
                        items += "bag of force";
                    } else if (d100 == 92){
                        items += "chime of opening";
                    } else if (d100 == 93){
                        items += "decanter of endless water";
                    } else if (d100 == 94){
                        items += "eyes of minute seeing";
                    } else if (d100 == 95){
                        items += "folding boat";
                    } else if (d100 == 96){
                        items += "Heward's handy haversacks";
                    } else if (d100 == 97){
                        items += "horseshoes of speed";
                    } else if (d100 == 98){
                        items += "necklace of fireballs";
                    } else if (d100 == 99){
                        items += "periapt of health";
                    } else if (d100 == 100){
                        items += "sending stones";
                    }
                break;
                case 'D':
                    if (d100 <= 20){
                        items += "potion of supreme healing";
                    } else if (d100 <= 30){
                        items += "potion of invisibility";
                    } else if (d100 <= 40){
                        items += "potion of speed";
                    } else if (d100 <= 50){
                        items += "spell scroll (6th level)";
                    } else if (d100 <= 57){
                        items += "spell scroll (7th level)";
                    } else if (d100 <= 62){
                        items += "ammunition, +3";
                    } else if (d100 <= 67){
                        items += "oil of sharpness";
                    } else if (d100 <= 72){
                        items += "potion of flying";
                    } else if (d100 <= 77){
                        items += "potion of cloud giant strength";
                    } else if (d100 <= 82){
                        items += "potion of longevity";
                    } else if (d100 <= 87){
                        items += "potion of vitality";
                    } else if (d100 <= 92){
                        items += "spell scroll (8th level)";
                    } else if (d100 <= 95){
                        items += "horsehoes of a zephyr";
                    } else if (d100 <= 98){
                        items += "Nolzur's marvelous pigments";
                    } else if (d100 == 99){
                        items += "bag of devouring";
                    } else if (d100 == 100){
                        items += "portable hole";
                    }
                break;
                case 'E':
                    if (d100 <= 30){
                        items += "spell scroll (8th level)";
                    } else if (d100 <= 55){
                        items += "potion of storm giant strength";
                    } else if (d100 <= 70){
                        items += "potion of supreme healing";
                    } else if (d100 <= 85){
                        items += "spell scroll (9th level)";
                    } else if (d100 <= 93){
                        items += "universal solvent";
                    } else if (d100 <= 98){
                        items += "arrow of slaying";
                    } else if (d100 <= 100){
                        items += "sovereign glue";
                    } 
                break;
                case 'F':
                    if (d100 <= 15){
                        items += "weapon, +1";
                    } else if (d100 <= 18){
                        items += "shield, +1";
                    } else if (d100 <= 21){
                        items += "ammunition, +2";
                    } else if (d100 <= 23){
                        items += "amulet of proof against detection and location";
                    } else if (d100 <= 25){
                        items += "boots of elvenkind";
                    } else if (d100 <= 27){
                        items += "boots of striding and springing";
                    } else if (d100 <= 29){
                        items += "bracers of archery";
                    } else if (d100 <= 31){
                        items += "brooch of shielding";
                    } else if (d100 <= 33){
                        items += "broom of flying";
                    } else if (d100 <= 35){
                        items += "cloak of elvenkind";
                    } else if (d100 <= 37){
                        items += "cloak of protection";
                    } else if (d100 <= 39){
                        items += "gaunlets of ogre power";
                    } else if (d100 <= 41){
                        items += "hat of disguise";
                    } else if (d100 <= 43){
                        items += "javelin of lightning";
                    } else if (d100 <= 45){
                        items += "pearl of power";
                    } else if (d100 <= 47){
                        items += "rod of the pact keeper, +1";
                    } else if (d100 <= 49){
                        items += "slippers of spider climbing";
                    } else if (d100 <= 51){
                        items += "staff of the adder";
                    } else if (d100 <= 53){
                        items += "staff of the python";
                    } else if (d100 <= 55){
                        items += "sword of vengeance";
                    } else if (d100 <= 57){
                        items += "trident of fish command";
                    } else if (d100 <= 59){
                        items += "wand of magic missiles";
                    } else if (d100 <= 61){
                        items += "wand of the war mage, + 1";
                    } else if (d100 <= 63){
                        items += "wand of web";
                    } else if (d100 <= 65){
                        items += "weapon of warning";
                    } else if (d100 == 66){
                        items += "adamantine armor (chain mail)";
                    } else if (d100 == 67){
                        items += "adamantine armor (chain shirt)";
                    } else if (d100 == 68){
                        items += "adamantine armor (scale mail)";
                    } else if (d100 == 69){
                        items += "bag of tricks (gray)";
                    } else if (d100 == 70){
                        items += "bag of tricks (rust)";
                    } else if (d100 == 71){
                        items += "bag of tricks (tan)";
                    } else if (d100 == 72){
                        items += "boots of the winterlands";
                    } else if (d100 == 73){
                        items += "circlet of blasting";
                    } else if (d100 == 74){
                        items += "deck of illusions";
                    } else if (d100 == 75){
                        items += "eversmoking bottle";
                    } else if (d100 == 76){
                        items += "eyes of charming";
                    } else if (d100 == 77){
                        items += "eyes of the eagle";
                    } else if (d100 == 78){
                        items += "figurine of wondrous power (silver raven)";
                    } else if (d100 == 79){
                        items += "gem of brightness";
                    } else if (d100 == 80){
                        items += "gloves of missile snaring";
                    } else if (d100 == 81){
                        items += "gloves of swimming and climbing";
                    } else if (d100 == 82){
                        items += "gloves of thievery";
                    } else if (d100 == 83){
                        items += "headband of intellect";
                    } else if (d100 == 84){
                        items += "helm of telepathy";
                    } else if (d100 == 85){
                        items += "instrument of the bards (doss lute)";
                    } else if (d100 == 86){
                        items += "instrument of the bards (fochlucan bandore)";
                    } else if (d100 == 87){
                        items += "instrument of the bards (mac-fuimidh cittern)";
                    } else if (d100 == 88){
                        items += "medallion of thoughts";
                    } else if (d100 == 89){
                        items += "necklace of adaptation";
                    } else if (d100 == 90){
                        items += "periapt of wound closure";
                    } else if (d100 == 91){
                        items += "pipes of haunting";
                    } else if (d100 == 92){
                        items += "pipes of the sewers";
                    } else if (d100 == 93){
                        items += "ring of jumping";
                    } else if (d100 == 94){
                        items += "ring of mind shielding";
                    } else if (d100 == 95){
                        items += "ring of warmth";
                    } else if (d100 == 96){
                        items += "ring of water walking";
                    } else if (d100 == 97){
                        items += "quiver of Ehlonna";
                    } else if (d100 == 98){
                        items += "stone of good luck";
                    } else if (d100 == 99){
                        items += "wind fan";
                    } else if (d100 == 100){
                        items += "winged boots";
                    } 
                break;
                case 'G':
                    if (d100 <= 11){
                        items += "weapon, +2";
                    } else if (d100 <= 14){
                        let d8 = rollDice(1,8);
                        switch(d8){
                            case 1: 
                                items += "figurine of wonderous power (bronze griffon)";
                                break;
                            case 2: 
                                items += "figurine of wonderous power (ebony fly)";
                                break;
                            case 3:
                                items += "figurine of wonderous power (golden lions)";
                                break;
                            case 4: 
                                items += "figurine of wonderous power (ivory goats)";
                                break;
                            case 5: 
                                items += "figurine of wonderous power (marble elephant)";
                                break;
                            case 6: 
                                items += "figurine of wonderous power (onyx dog)";
                                break;
                            case 7: 
                                items += "figurine of wonderous power (onyx dog)";
                                break;
                            case 8: 
                                items += "figurine of wonderous power (serpentine owl)";
                                break;
                        }
                    } else if (d100 == 15){
                        items += "adamantine armor (breastplate)";
                    } else if (d100 == 16){
                        items += "adamantine armor (splint)";
                    } else if (d100 == 17){
                        items += "amulet of health";
                    } else if (d100 == 18){
                        items += "armor of vulnerability";
                    } else if (d100 == 19){
                        items += "arrow-catching sheild";
                    } else if (d100 == 20){
                        items += "belt of dwarvenkind";
                    } else if (d100 == 21){
                        items += "belt of hill giant strength";
                    } else if (d100 == 22){
                        items += "berserker axe";
                    } else if (d100 == 23){
                        items += "boots of levitation";
                    } else if (d100 == 24){
                        items += "boots of speed";
                    } else if (d100 == 25){
                        items += "bowl of commanding water elementals";
                    } else if (d100 == 26){
                        items += "bracers of defense";
                    } else if (d100 == 27){
                        items += "brazier of commanding fire elementals";
                    } else if (d100 == 28){
                        items += "cape of mountebank";
                    } else if (d100 == 29){
                        items += "censer of controlling air elementals";
                    } else if (d100 == 30){
                        items += "armor, +1 chain mail";
                    } else if (d100 == 31){
                        items += "armor of resistance (chain mail)";
                    } else if (d100 == 32){
                        items += "armor of resistance (chain shirt)";
                    } else if (d100 == 33){
                        items += "armor, +1 chain shirt";
                    } else if (d100 == 34){
                        items += "cloak of displacement";
                    } else if (d100 == 35){
                        items += "cloak of the bat";
                    } else if (d100 == 36){
                        items += "cube of force";
                    } else if (d100 == 37){
                        items += "Daern's instant fortress";
                    } else if (d100 == 38){
                        items += "dagger of venom";
                    } else if (d100 == 39){
                        items += "dimensional shackles";
                    } else if (d100 == 40){
                        items += "dragon slayer";
                    } else if (d100 == 41){
                        items += "elven chain";
                    } else if (d100 == 42){
                        items += "flame tongue";
                    } else if (d100 == 43){
                        items += "gem of seeing";
                    } else if (d100 == 44){
                        items += "giant slayer";
                    } else if (d100 == 45){
                        items += "clamoured studded leather";
                    } else if (d100 == 46){
                        items += "helm of teleportation";
                    } else if (d100 == 47){
                        items += "horn of blasting";
                    } else if (d100 == 48){
                        items += "horn of valhalla (silver or brass)";
                    } else if (d100 == 49){
                        items += "instrument of the bards (canaith mandolin)";
                    } else if (d100 == 50){
                        items += "instrument of the bards (cii lyre)";
                    } else if (d100 == 51){
                        items += "ioun stone (awareness)";
                    } else if (d100 == 52){
                        items += "inoun stone (protection)";
                    } else if (d100 == 53){
                        items += "ioun stone (reserve)";
                    } else if (d100 == 54){
                        items += "ioun stone (sustenance)";
                    } else if (d100 == 55){
                        items += "iron bands of Bilarro";
                    } else if (d100 == 56){
                        items += "armor, +1 leather";
                    } else if (d100 == 57){
                        items += "armor of resistance (leather)";
                    } else if (d100 == 58){
                        items += "mace of disruption";
                    } else if (d100 == 59){
                        items += "mace of smiting";
                    } else if (d100 == 60){
                        items += "mace of terror";
                    } else if (d100 == 61){
                        items += "mantle of spell resistance";
                    } else if (d100 == 62){
                        items += "necklace of prayer beads";
                    } else if (d100 == 63){
                        items += "periapt of proof against poison";
                    } else if (d100 == 64){
                        items += "ring of animal influence";
                    } else if (d100 == 65){
                        items += "ring of evasion";
                    } else if (d100 == 66){
                        items += "ring of feather falling";
                    } else if (d100 == 67){
                        items += "ring of free action";
                    } else if (d100 == 68){
                        items += "ring of protection";
                    } else if (d100 == 69){
                        items += "ring of resistance";
                    } else if (d100 == 70){
                        items += "ring of spell storing";
                    } else if (d100 == 71){
                        items += "ring of the ram";
                    } else if (d100 == 72){
                        items += "ring of x-ray vision";
                    } else if (d100 == 73){
                        items += "robe of eyes";
                    } else if (d100 == 74){
                        items += "rod of rulership";
                    } else if (d100 == 75){
                        items += "rod of the pact keeper, +2";
                    } else if (d100 == 76){
                        items += "rope of entanglement";
                    } else if (d100 == 77){
                        items += "armor, +1 scale mail";
                    } else if (d100 == 78){
                        items += "armor of resistance (scale mail)";
                    } else if (d100 == 79){
                        items += "shield, +2";
                    } else if (d100 == 80){
                        items += "shield of missle attraction";
                    } else if (d100 == 81){
                        items += "staff of charming";
                    } else if (d100 == 82){
                        items += "staff of healing";
                    } else if (d100 == 83){
                        items += "staff of swarming insects";
                    } else if (d100 == 84){
                        items += "staff of the woodlands";
                    } else if (d100 == 85){
                        items += "staff of withering";
                    } else if (d100 == 86){
                        items += "stone of controlling earth elementals";
                    } else if (d100 == 87){
                        items += "sun blade";
                    } else if (d100 == 88){
                        items += "sword of life stealing";
                    } else if (d100 == 89){
                        items += "sword of wounding";
                    } else if (d100 == 90){
                        items += "tentacle rod";
                    } else if (d100 == 91){
                        items += "vicious weapon";
                    } else if (d100 == 92){
                        items += "wand of binding";
                    } else if (d100 == 93){
                        items += "wand of enemy detection";
                    } else if (d100 == 94){
                        items += "wand of fear";
                    } else if (d100 == 95){
                        items += "wand of fireballs";
                    } else if (d100 == 96){
                        items += "wand of lightning bolts";
                    } else if (d100 == 97){
                        items += "wand of paralysis";
                    } else if (d100 == 98){
                        items += "wand of the war mage, +2";
                    } else if (d100 == 99){
                        items += "wand of wonder";
                    } else if (d100 == 100){
                        items += "wings of flying";
                    } 
                break;
                case 'H':
                    if (d100 <= 10){
                        items += "weapon, +3";
                    } else if (d100 <= 12){
                        items += "amulet of the planes";
                    } else if (d100 <= 14){
                        items += "carpet of flying";
                    } else if (d100 <= 16){
                        items += "crystal ball (very rare version)";
                    } else if (d100 <= 18){
                        items += "ring of regeneration";
                    } else if (d100 <= 20){
                        items += "ring of shooting stars";
                    } else if (d100 <= 22){
                        items += "ring of telekinesis";
                    } else if (d100 <= 24){
                        items += "robe of scintillating colors";
                    } else if (d100 <= 26){
                        items += "robe of stars";
                    } else if (d100 <= 28){
                        items += "rod of absorption";
                    } else if (d100 <= 30){
                        items += "rod of alertness";
                    } else if (d100 <= 32){
                        items += "rod of security";
                    } else if (d100 <= 34){
                        items += "rod of the pact keeper, +3";
                    } else if (d100 <= 36){
                        items += "scimitar of speed";
                    } else if (d100 <= 38){
                        items += "shield, +3";
                    } else if (d100 <= 40){
                        items += "staff of fire";
                    } else if (d100 <= 42){
                        items += "staff of frost";
                    } else if (d100 <= 44){
                        items += "staff of power";
                    } else if (d100 <= 46){
                        items += "staff of striking";
                    } else if (d100 <= 48){
                        items += "staff of thunder and lightning";
                    } else if (d100 <= 50){
                        items += "sword of sharpness";
                    } else if (d100 <= 52){
                        items += "wand of polymorph";
                    } else if (d100 <= 54){
                        items += "wand of the war mage, +3";
                    } else if (d100 == 55){
                        items += "adamantine armor (half plate)";
                    } else if (d100 == 56){
                        items += "adamantine armor (plate)";
                    } else if (d100 == 57){
                        items += "animated shield";
                    } else if (d100 == 58){
                        items += "belt of fire giant strength";
                    } else if (d100 == 59){
                        items += "belt of frost (or stone) giant strength";
                    } else if (d100 == 60){
                        items += "armor, +1 breastplate";
                    } else if (d100 == 61){
                        items += "armor of resistance (breastplate)";
                    } else if (d100 == 62){
                        items += "candle of invocation";
                    } else if (d100 == 63){
                        items += "armor, +2 chain mail";
                    } else if (d100 == 64){
                        items += "armor, +2 chain shirt";
                    } else if (d100 == 65){
                        items += "cloak of arachnida";
                    } else if (d100 == 66){
                        items += "dancing sword";
                    } else if (d100 == 67){
                        items += "demon armor";
                    } else if (d100 == 68){
                        items += "dragon scale mail";
                    } else if (d100 == 69){
                        items += "dwarven plate";
                    } else if (d100 == 70){
                        items += "dwarven thrower";
                    } else if (d100 == 71){
                        items += "efreeti bottle";
                    } else if (d100 == 72){
                        items += "figurine of wonderous power (obsidian steed)";
                    } else if (d100 == 73){
                        items += "frost brand";
                    } else if (d100 == 74){
                        items += "helm of brillance";
                    } else if (d100 == 75){
                        items += "horn of valhalla (bronze)";
                    } else if (d100 == 76){
                        items += "instrument of the bards (anstruth harp)";
                    } else if (d100 == 77){
                        items += "ioun stone (absorption)";
                    } else if (d100 == 78){
                        items += "ioun stone (agility)";
                    } else if (d100 == 79){
                        items += "ioun stone (fortitude)";
                    } else if (d100 == 80){
                        items += "ioun stone (insight)";
                    } else if (d100 == 81){
                        items += "ioun stone (intellect)";
                    } else if (d100 == 82){
                        items += "ioun stone (leadership)";
                    } else if (d100 == 83){
                        items += "ioun stone (strength)";
                    } else if (d100 == 84){
                        items += "armor, +2 leather";
                    } else if (d100 == 85){
                        items += "manual of bodily health";
                    } else if (d100 == 86){
                        items += "manual of gainful exercise";
                    } else if (d100 == 87){
                        items += "manual of golems";
                    } else if (d100 == 88){
                        items += "manual of quickness of action";
                    } else if (d100 == 89){
                        items += "mirror of life trapping";
                    } else if (d100 == 90){
                        items += "nine lives stealer";
                    } else if (d100 == 91){
                        items += "oathbow";
                    } else if (d100 == 92){
                        items += "armor, +2 scale mail";
                    } else if (d100 == 93){
                        items += "spell guard sheild";
                    } else if (d100 == 94){
                        items += "armor, +1 splint";
                    } else if (d100 == 95){
                        items += "armor of resistance (splint)";
                    } else if (d100 == 96){
                        items += "armor, +1 studded leather";
                    } else if (d100 == 97){
                        items += "armor of resistance (studded leather)";
                    } else if (d100 == 98){
                        items += "tome of clear thought";
                    } else if (d100 == 99){
                        items += "tome of leadership and influence";
                    } else if (d100 == 100){
                        items += "tome of understanding";
                    } 
                break;
            }
        }
        return items;
    }

    function generateTreasure(){
        
        let CR = selectedTreasure;
        let treasure = "";

        if(CR == 'ind0'){
            let d100 = rollDice(1, 100);
            //console.log(d100);

            if (d100 <= 30){
                treasure = rollDice(5,6) + " cp";
            } else if (d100 <= 60){
                treasure = rollDice(4,6) + " sp";
            } else if (d100 <= 70){
                treasure = rollDice(3,6) + " ep";
            } else if (d100 <= 95) {
                treasure = rollDice(3,6) + " gp";
            } else {
                treasure = rollDice(1,6) + " pp";
            }

            return treasure;

        } else if (CR == 'ind5') {
            let d100 = rollDice(1, 100);
            //console.log(d100);

            if (d100 <= 30){
                treasure = rollDice(4,6)*100 + " cp, " + rollDice(1,6)*10 + " ep";
            } else if (d100 <= 60){
                treasure = rollDice(6,6)*10 + " sp, " + rollDice(2,6)*10 + " gp";
            } else if (d100 <= 70){
                treasure = rollDice(1,6)*100 + " ep, " + rollDice(2,6)*10 + " gp";
            } else if (d100 <= 95) {
                treasure = rollDice(4,6)*10 + " gp";
            } else {
                treasure = rollDice(2,6)*10 + " gp, " + rollDice(3,6) + " pp";
            }

            return treasure;

        } else if (CR == 'ind11') {
            let d100 = rollDice(1, 100);
            //console.log(d100);

            if (d100 <= 20){
                treasure = rollDice(4,6)*100 + " sp, " + rollDice(1,6)*100 + " gp";
            } else if (d100 <= 35){
                treasure = rollDice(1,6)*100 + " ep, " + rollDice(1,6)*100 + " gp";
            } else if (d100 <= 75){
                treasure = rollDice(2,6)*100 + " gp, " + rollDice(1,6)*10 + " pp";
            } else {
                treasure = rollDice(2,6)*100 + " gp, " + rollDice(2,6)*10 + " pp";
            }

            return treasure;

        } else if (CR == 'ind17') {
            let d100 = rollDice(1, 100);
            //console.log(d100);

            if (d100 <= 15){
                treasure = rollDice(2,6)*1000 + " ep, " + rollDice(8,6)*100 + " gp";
            } else if (d100 <= 55){
                treasure = rollDice(1,6)*1000 + " gp, " + rollDice(1,6)*100 + " pp";
            } else {
                treasure = rollDice(1,6)*1000 + " gp, " + rollDice(2,6)*100 + " pp";
            }

            return treasure;

        } else if (CR == 'hrd0') {
            // add coins to treasure
            treasure += rollDice(6,6)*100 + " cp, " + rollDice(3,6)*100 + " sp, " + rollDice(2,6)*10 + " gp";
            let d100 = rollDice(1, 100);
            //console.log(d100);

            if(d100 <= 6){
                return treasure;
            } else if (d100 <= 16){
                treasure += ", " + getGems(rollDice(2,6), 10);
            } else if (d100 <= 26){
                treasure += ", " + getArt(rollDice(2,4), 25);
            } else if (d100 <= 36){
                treasure += ", " + getGems(rollDice(2,6), 50);
            } else if (d100 <= 44){
                treasure += ", " + getGems(rollDice(2,6), 10) + ", " + getMagicItems(rollDice(1,6), 'A');
            } else if (d100 <= 52){
                treasure += ", " + getArt(rollDice(2,4), 25) + ", " + getMagicItems(rollDice(1,6), 'A');
            } else if (d100 <= 60){
                treasure += ", " + getGems(rollDice(2,6), 50) + ", " + getMagicItems(rollDice(1,6), 'A');
            } else if (d100 <= 65){
                treasure += ", " + getGems(rollDice(2,6), 10) + ", " + getMagicItems(rollDice(1,4), 'B');
            } else if (d100 <= 70){
                treasure += ", " + getArt(rollDice(2,4), 25) + ", " + getMagicItems(rollDice(1,4), 'B');
            } else if (d100 <= 75){
                treasure += ", " + getGems(rollDice(2,6), 50) + ", " + getMagicItems(rollDice(1,4), 'B');
            } else if (d100 <= 78){
                treasure += ", " + getGems(rollDice(2,6), 10) + ", " + getMagicItems(rollDice(1,4), 'C');
            } else if (d100 <= 80){
                treasure += ", " + getArt(rollDice(2,4), 25) + ", " + getMagicItems(rollDice(1,4), 'C');
            } else if (d100 <= 85){
                treasure += ", " + getGems(rollDice(2,6), 50) + ", " + getMagicItems(rollDice(1,4), 'C');
            } else if (d100 <= 92){
                treasure += ", " + getArt(rollDice(2,4), 25) + ", " + getMagicItems(rollDice(1,4), 'F');
            } else if (d100 <= 97){
                treasure += ", " + getGems(rollDice(2,6), 50) + ", " + getMagicItems(rollDice(1,4), 'F');
            } else if (d100 <= 99){
                treasure += ", " + getArt(rollDice(2,4), 25) + ", " + getMagicItems(1, 'G');
            } else if (d100 == 100){
                treasure += ", " + getGems(rollDice(2,6), 50) + ", " + getMagicItems(1, 'G');
            }

            return treasure;

        } else if (CR == 'hrd5') {
            // add coins to treasure
            treasure += rollDice(2,6)*100 + " cp, " + rollDice(2,6)*1000 + " sp, " + rollDice(6,6)*100 + " gp, " + rollDice(3,6)*10 + " pp";
            let d100 = rollDice(1, 100);
            //console.log(d100);

            if(d100 <= 4){
                return treasure;
            } else if (d100 <= 10){
                treasure += ", " + getArt(rollDice(2,4), 25);
            } else if (d100 <= 16){
                treasure += ", " + getGems(rollDice(3,6), 50);
            } else if (d100 <= 22){
                treasure += ", " + getGems(rollDice(3,6), 100);
            } else if (d100 <= 28){
                treasure += ", " + getArt(rollDice(2,4), 25);
            } else if (d100 <= 32){
                treasure += ", " + getArt(rollDice(2,4), 25) + ", " + getMagicItems(rollDice(1,6), 'A');
            } else if (d100 <= 36){
                treasure += ", " + getGems(rollDice(3,6), 50) + ", " + getMagicItems(rollDice(1,6), 'A');
            } else if (d100 <= 40){
                treasure += ", " + getGems(rollDice(3,6), 100) + ", " + getMagicItems(rollDice(1,6), 'A');
            } else if (d100 <= 44){
                treasure += ", " + getArt(rollDice(2,4), 250) + ", " + getMagicItems(rollDice(1,6), 'A');
            } else if (d100 <= 49){
                treasure += ", " + getArt(rollDice(2,4), 25) + ", " + getMagicItems(rollDice(1,4), 'B');
            } else if (d100 <= 54){
                treasure += ", " + getGems(rollDice(3,6), 50) + ", " + getMagicItems(rollDice(1,4), 'B');
            } else if (d100 <= 59){
                treasure += ", " + getGems(rollDice(3,6), 100) + ", " + getMagicItems(rollDice(1,4), 'B');
            } else if (d100 <= 63){
                treasure += ", " + getArt(rollDice(2,4), 250) + ", " + getMagicItems(rollDice(1,4), 'B');
            } else if (d100 <= 66){
                treasure += ", " + getArt(rollDice(2,4), 25) + ", " + getMagicItems(rollDice(1,4), 'C');
            } else if (d100 <= 69){
                treasure += ", " + getGems(rollDice(3,6), 50) + ", " + getMagicItems(rollDice(1,4), 'C');
            } else if (d100 <= 72){
                treasure += ", " + getGems(rollDice(3,6), 100) + ", " + getMagicItems(rollDice(1,4), 'C');
            } else if (d100 <= 74){
                treasure += ", " + getArt(rollDice(2,4), 250) + ", " + getMagicItems(rollDice(1,4), 'C');
            } else if (d100 <= 76){
                treasure += ", " + getArt(rollDice(2,4), 25) + ", " + getMagicItems(1, 'D');
            } else if (d100 <= 78){
                treasure += ", " + getGems(rollDice(3,6), 50) + ", " + getMagicItems(1, 'D');
            } else if (d100 <= 79){
                treasure += ", " + getGems(rollDice(3,6), 100) + ", " + getMagicItems(1, 'D');
            } else if (d100 <= 80){
                treasure += ", " + getArt(rollDice(2,4), 250) + ", " + getMagicItems(1, 'D');
            } else if (d100 <= 84){
                treasure += ", " + getArt(rollDice(2,4), 25) + ", " + getMagicItems(rollDice(1,4), 'F');
            } else if (d100 <= 88){
                treasure += ", " + getGems(rollDice(3,6), 50) + ", " + getMagicItems(rollDice(1,4), 'F');
            } else if (d100 <= 91){
                treasure += ", " + getGems(rollDice(3,6), 100) + ", " + getMagicItems(rollDice(1,4), 'F');
            } else if (d100 <= 94){
                treasure += ", " + getArt(rollDice(2,4), 250) + ", " + getMagicItems(rollDice(1,4), 'F');
            } else if (d100 <= 96){
                treasure += ", " + getGems(rollDice(3,6), 100) + ", " + getMagicItems(rollDice(1,4), 'G');
            } else if (d100 <= 98){
                treasure += ", " + getArt(rollDice(2,4), 250) + ", " + getMagicItems(rollDice(1,6), 'G');
            } else if (d100 == 99){
                treasure += ", " + getGems(rollDice(3,6), 100) + ", " + getMagicItems(1, 'H');
            } else if (d100 == 100){
                treasure += ", " + getArt(rollDice(2,4), 250) + ", " + getMagicItems(1, 'H');
            }

            return treasure;

        } else if (CR == 'hrd11') {
            let d100 = rollDice(1, 100);
            console.log(d100);

        } else if (CR == 'hrd17') {
            let d100 = rollDice(1, 100);
            console.log(d100);

        } else {
            console.log("Invalid selection on Treasure Generator.")
        }

    }

    function displayTreasure(){
        let treasureList = ['', '', '', ''];
        let CR = selectedTreasure;

        if(CR == 'ind0' || CR == 'ind5' || CR == 'ind11' || CR == 'ind17'){
            for (let i = 0; i < 4; i++){
                treasureList[i] = generateTreasure();
            }
        } else {
            treasureList[0] = generateTreasure();
        }

        setTreasureHoard(treasureList);
        setHidden("");
    }

    const handleSelectChange = (event) => {
        setSelectedTreasure(event.target.value);
    }

    return(
        <div>
            <section className="card">
                <h2>Treasure Generator</h2>
                <div id="generator">
                    <select name="treasure" id="treasure" className="select-bar" value={selectedTreasure} onChange={handleSelectChange}>
                        <option value="ind0">Individual (CR 0-4)</option>
                        <option value="ind5">Individual (CR 5-10)</option>
                        <option value="ind11">Individual (CR 11-16)</option>
                        <option value="ind17">Individual (CR 17+)</option>
                        <option value="hrd0">Hoard (CR 0-4)</option>
                        <option value="hrd5">Hoard (CR 5-10)</option>
                        <option value="hrd11">Hoard (CR 11-16)</option>
                        <option value="hrd17">Hoard (CR 17+)</option>
                    </select>
                    <button id="generate-treasure-button" type="submit" onClick={displayTreasure}><img className="search-button" alt="Enter search" title="Enter search" src={Submit} /></button>
                </div>
                <div id="results">
                    <h3 id="results-title" className={hidden}>Treasure</h3>
                    <ul id="results-list" className={hidden}>
                        <li>{treasureHoard[0]}</li>
                        {selectedTreasure == 'ind0' | selectedTreasure == 'ind5' | selectedTreasure == 'ind11' | selectedTreasure == 'ind17' ? <li>{treasureHoard[1]}</li> : null}
                        {selectedTreasure == 'ind0' | selectedTreasure == 'ind5' | selectedTreasure == 'ind11' | selectedTreasure == 'ind17' ? <li>{treasureHoard[2]}</li> : null}
                        {selectedTreasure == 'ind0' | selectedTreasure == 'ind5' | selectedTreasure == 'ind11' | selectedTreasure == 'ind17' ? <li>{treasureHoard[3]}</li> : null}
                    </ul>
                </div>
            </section>
            <section className="card">
                <h3>Wealth per Level</h3>
                <table>
                    <thead>
                        <tr>
                            <td>Level</td>
                            <td>Starting wealth*</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Starting gear</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>100 gp</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>200 gp</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>400 gp</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>700 gp</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>2900 gp</td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>5400 gp</td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>8600 gp</td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td>12,000 gp</td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td>16,600 gp</td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>21,100 gp</td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>30,200 gp</td>
                        </tr>
                        <tr>
                            <td>13</td>
                            <td>39,200 gp</td>
                        </tr>
                        <tr>
                            <td>14</td>
                            <td>57,300 gp</td>
                        </tr>
                        <tr>
                            <td>15</td>
                            <td>75,400 gp</td>
                        </tr>
                        <tr>
                            <td>16</td>
                            <td>102,600 gp</td>
                        </tr>
                        <tr>
                            <td>17</td>
                            <td>129,700 gp</td>
                        </tr>
                        <tr>
                            <td>18</td>
                            <td>214,200 gp</td>
                        </tr>
                        <tr>
                            <td>19</td>
                            <td>383,100 gp</td>
                        </tr>
                        <tr>
                            <td>20</td>
                            <td>552,000 gp</td>
                        </tr>
                        <tr>
                            <td>20+</td>
                            <td>805,400 gp</td>
                        </tr>
                    </tbody>
                </table>
                <p>* Amounts taken from <a href="https://rpg.stackexchange.com/questions/197081/how-much-gold-should-a-character-gain-per-level">this discussion</a> on rpg stackexchange.</p>
            </section>
            <section className="card">
                <h2>Treasure Search</h2>
                <Search 
                    dataPath={'/data/treasure.json'}
                    dataType={'treasure'}
                    columns={['Name', 'Price']}
                />
            </section>
        </div>
    );
}

export default Treasure;