import '../css/Nav.css';
import { NavLink } from 'react-router-dom';

function Nav(props){
    return(
        <nav className="">
            <ul id="nav-dropdown" className="nav-ul">
                <li><NavLink to='/'>General</NavLink></li>
                <li><NavLink to='/experience'>Experience</NavLink></li>
                <li><NavLink to='/inns'>Inns</NavLink></li>
                <li><NavLink to='/magicitems'>Magic Items</NavLink></li>
                <li><NavLink to='/prices'>Prices</NavLink></li>
                <li><NavLink to='/treasure'>Treasure</NavLink></li>
            </ul>
        </nav>
    );
}

export default Nav;