import { useEffect, useState } from 'react';
import '../css/Body.css';
import axios from 'axios';
import Search from '../components/Search';

function Prices(props){
    const [priceData, setPriceData] = useState([]);

    useEffect(() => {
        axios.get('/data/prices.json').then((res) => {
            setPriceData(res.data.prices);
        });
    }, []);

    return(
        <div>
            <section className="card">
                <table>
                    <thead>
                        <tr>
                            <td>Lifestyle</td>
                            <td>Price/Day</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Wretched</td>
                            <td>---</td>
                        </tr>
                        <tr>
                            <td>Squalid</td>
                            <td>1 sp</td>
                        </tr>
                        <tr>
                            <td>Poor</td>
                            <td>2 sp</td>
                        </tr>
                        <tr>
                            <td>Modest</td>
                            <td>1 gp</td>
                        </tr>
                        <tr>
                            <td>Comfortable</td>
                            <td>2 gp</td>
                        </tr>
                        <tr>
                            <td>Wealthy</td>
                            <td>4 gp</td>
                        </tr>
                        <tr>
                            <td>Aristocratic</td>
                            <td>10+ gp</td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section className="card">
                <h2>Pricing Search</h2>
                <Search 
                    dataPath={'/data/prices.json'}
                    dataType={'prices'}
                    columns={['Category', 'Name', 'Price']}
                />
            </section>
        </div>
    );
}

export default Prices;